import logo from './logo.svg';
import dogepfp from './doge1.jpg';
import store1 from './/img/bg2.jpg';

import rwmall from './/img/bg.jpg';
import inside from './/img/bg0.jpg';


import t1 from './/products/tea/6.jpg';
import t2 from './/products/tea/1.jpg';
import t0 from './/products/tea/t0.jpg';
import t3 from './/products/tea/2.jpg';
import t4 from './/products/tea/3.jpg';
import t44 from './/products/tea/4.jpg';
import t6 from './/products/tea/5.jpg';
import t5 from './/products/tea/7.jpg';
import t7 from './/products/tea/8.jpg';
import teas from './/products/tea/9.jpg';


import c1 from './/products/cold/cold1.png';
import c2 from './/products/cold/cold2.jpg';
import c3 from './/products/cold/c3.jpg';

import g0 from './/products/ginseng/g0.jpg';
import g1 from './/products/ginseng/g1.jpg';
import g2 from './/products/ginseng/g2.jpg';
import g4 from './/products/ginseng/g4.jpg';
import g5 from './/products/ginseng/g6.jpg';


import v1 from './/products/v.png';
import o1 from './/products/oils/o1.jpg';
import o2 from './/products/oils/o2.jpg';
import o3 from './/products/oils/o3.png';
import o4 from './/products/oils/o4.jpg';
import o5 from './/products/oils/o5.jpg';


import a0 from './/products/sleepanxiety/a0.png';
import a1 from './/products/sleepanxiety/a1.jpg';
import a2 from './/products/sleepanxiety/a2.png';
import a4 from './/products/sleepanxiety/a4.jpg';
import a5 from './/products/sleepanxiety/a5.jpg';

import d1 from './/products/detox/d1.jpg';
import d2 from './/products/detox/d2.png';
import d3 from './/products/detox/d3.png';


import w0 from './/products/weightloss/w0.png';
import w1 from './/products/weightloss/w1.jpg';
import w2 from './/products/weightloss/w2.jpg';
import w3 from './/products/weightloss/w3.jpg';
import w4 from './/products/weightloss/w4.jpg';
import w5 from './/products/weightloss/w5.jpg';

import p1 from './/products/pain1/p1.jpg';
import p2 from './/products/pain1/p2.jpg';
import p3 from './/products/pain1/p3.jpg';

import k0 from './/products/liverkidney/k0.jpg';
import k1 from './/products/liverkidney/k1.jpg';
import k2 from './/products/liverkidney/k2.jpg';
import k3 from './/products/liverkidney/k3.jpg';
import k4 from './/products/liverkidney/k4.jpg';

import s1 from './/products/skin/s1.jpg';

import s2 from './/products/skin/s2.jpg';


import './App.css';

function App() {
  return (
    <div className="App">
    
    <div class="navbar shadow-lg bg-neutral text-neutral-content bg-primary	">
  <div class="px-2 mx-2 navbar-start">
    <span class="text-lg font-bold">
    <h1 class="mb-2 text-5xl font-bold">

    晶森 </h1> 
          </span>
  </div> 
  <div class="hidden px-2 mx-2 navbar-center lg:flex">
    WE ARE OPEN AND AVAILIBLE FOR PICK UP. IF YOU ARE VISITING IN PERSON PLEASE RESPECT COVID PROTOCOLS. THANK YOU.
    {/* <div class="flex items-stretch">
      <a class="btn btn-ghost btn-sm rounded-btn">
              Home
            </a> 
      <a class="btn btn-ghost btn-sm rounded-btn">
              Portfolio
            </a> 
      <a class="btn btn-ghost btn-sm rounded-btn">
              About
            </a> 
      <a class="btn btn-ghost btn-sm rounded-btn">
              Contact
            </a>
    </div> */}
  </div> 
  <div class="navbar-end">
    {/* <button class="btn btn-square btn-ghost">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-6 h-6 stroke-current">     
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>                     
      </svg>
    </button> 
    <button class="btn btn-square btn-ghost">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-6 h-6 stroke-current">             
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>             
      </svg>
    </button> */}
  </div>
</div>
{/* 
<div class="hero min-h-screen bg-base-200">
  <div class="text-center hero-content">
    <div class="max-w-md">
      <h1 class="mb-5 text-5xl font-bold">
            Hello there
          </h1> 
      <p class="mb-5">
            Provident cupiditate voluptatem et in. Quaerat fugiat ut assumenda excepturi exercitationem quasi. In deleniti eaque aut repudiandae et a id nisi.
          </p> 
      <button class="btn btn-primary">Get Started</button>
    </div>
  </div>
</div> */}



<div class="hero min-h-screen" style={{ backgroundImage: `url(${dogepfp})`}}>
  <div class="hero-overlay bg-opacity-60"></div> 
  <div class="text-center hero-content text-neutral-content">
    <div class="max-w-md">
      
      <h1 class="mb-5 text-5xl font-bold">
Ginsengg Inc.         </h1> 
      <p class="mb-5">
Traditional Chinese Medicine, Natural Herbs, Imported teas, and More          </p> 
      {/* <button class="btn btn-primary">v</button> */}
    </div>
  </div>
</div>








<div class="hero .h-0 bg-base-200">
  <div class="flex-col hero-content lg:flex-row">

    {/* PRODUCT CARD */}

  <div class="max-w-md">
    
    <div class="card w-72 card-bordered card-compact lg:card-normal" 
>
    <figure>
    <div class="w-full carousel " >
    <div id="slide1" class="relative w-full pt-20 carousel-item">
    <img src={t1} class="w-full" style={{borderRadius:"25px"}}/>
 
    </div> 

    <div id="slide8" class="relative w-full pt-20 carousel-item">
    <img src={t0} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>


    <div id="slide2" class="relative w-full pt-20 carousel-item">
    <img src={t2} class="w-full" style={{borderRadius:"25px"}}/>
     
    </div> 
    <div id="slide9" class="relative w-full pt-20 carousel-item">
    <img src={t44} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> 
    <div id="slide3" class="relative w-full pt-20 carousel-item">
    <img src={t3} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> 
    <div id="slide4" class="relative w-full pt-20 carousel-item">
    <img src={t4} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>

    <div id="slide5" class="relative w-full pt-20 carousel-item">
    <img src={t5} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>

    <div id="slide6" class="relative w-full pt-20 carousel-item">
    <img src={t6} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>

    <div id="slide7" class="relative w-full pt-20 carousel-item">
    <img src={t7} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>

    
  </div>
    </figure> 
    <div class="card-body">
      <h2 class="card-title">Traditional Chinese Herbal Teas</h2> 
      <p>High quality & Authentic Chinese teas;
          Oolong, Green, Diet, Detox and more  </p>
    

    </div>
  </div>
  
      </div>  
      
    {/* PRODUCT CARD 2  */}
  
  <div class="max-w-md">
    
    <div class="card w-72 card-bordered card-compact lg:card-normal">
    <figure>
    <div class="w-full carousel">
    {/* <div id="slide1" class="relative w-full pt-20 carousel-item">
    <img src={t1} class="w-full" style={{borderRadius:"25px"}}/>
 
    </div>  */}

    <div id="slide8" class="relative w-full pt-20 carousel-item">
    <img src={g0} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>


    <div id="slide2" class="relative w-full pt-20 carousel-item">
    <img src={g1} class="w-full" style={{borderRadius:"25px"}}/>
     
    </div> 
    <div id="slide9" class="relative w-full pt-20 carousel-item">
    <img src={g2} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> 
    <div id="slide3" class="relative w-full pt-20 carousel-item">
    <img src={g4} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> 
    <div id="slide4" class="relative w-full pt-20 carousel-item">
    <img src={g5} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>
{/* 
    <div id="slide5" class="relative w-full pt-20 carousel-item">
    <img src={g5} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>

    <div id="slide6" class="relative w-full pt-20 carousel-item">
    <img src={t6} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>

    <div id="slide7" class="relative w-full pt-20 carousel-item">
    <img src={t7} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> */}

    
  </div>
    </figure> 
    <div class="card-body">
      <h2 class="card-title">Ginseng Herbs and Extracts</h2> 
      <p>Top Quality Ginseng Imported From China & Korea, Wild Peruvian Maca and Powders</p>
    </div>
  </div>
  
      </div>  

      
  <div class="max-w-md">
    
    <div class="card w-72 card-bordered card-compact lg:card-normal">
    <figure>
    <div class="w-full carousel">
    <div id="slide1" class="relative w-full pt-20 carousel-item">
    <img src={c1} class="w-full" style={{borderRadius:"25px"}}/>
 
    </div> 

    <div id="slide8" class="relative w-full pt-20 carousel-item">
    <img src={c2} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>


    <div id="slide2" class="relative w-full pt-20 carousel-item">
    <img src={c3} class="w-full" style={{borderRadius:"25px"}}/>
     
    </div> 
    {/* <div id="slide9" class="relative w-full pt-20 carousel-item">
    <img src={t44} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> 
    <div id="slide3" class="relative w-full pt-20 carousel-item">
    <img src={t3} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> 
    <div id="slide4" class="relative w-full pt-20 carousel-item">
    <img src={t4} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>

    <div id="slide5" class="relative w-full pt-20 carousel-item">
    <img src={t5} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>

    <div id="slide6" class="relative w-full pt-20 carousel-item">
    <img src={t6} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>

    <div id="slide7" class="relative w-full pt-20 carousel-item">
    <img src={t7} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> */}

    
  </div>
    </figure> 
    <div class="card-body">
      <h2 class="card-title">Cold & Flu </h2> 
      <h2 class="card-title"> </h2> 
      <h2 class="card-title"> </h2> 

      <p>Tablets, teas and cough syrups for cold and flu recovery/prevention</p>
    </div>
  </div>
  
      </div>  
      {/* PRODUCT CARD */}
      
      
    
      
      <div class="max-w-md">
    
    <div class="card w-72 card-bordered card-compact lg:card-normal">
    <figure>
    <div class="w-full carousel">
    <div id="slide1" class="relative w-full pt-20 carousel-item">
    <img src={v1} class="w-full" style={{borderRadius:"25px"}}/>
 
    </div> 

    <div id="slide8" class="relative w-full pt-20 carousel-item">
    <img src={o1} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>


    <div id="slide2" class="relative w-full pt-20 carousel-item">
    <img src={o2} class="w-full" style={{borderRadius:"25px"}}/>
     
    </div> 
    <div id="slide9" class="relative w-full pt-20 carousel-item">
    <img src={o3} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> 
    <div id="slide3" class="relative w-full pt-20 carousel-item">
    <img src={o4} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> 
    <div id="slide4" class="relative w-full pt-20 carousel-item">
    <img src={o5} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>
 {/*
    <div id="slide5" class="relative w-full pt-20 carousel-item">
    <img src={o6} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>

    <div id="slide6" class="relative w-full pt-20 carousel-item">
    <img src={t6} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>

    <div id="slide7" class="relative w-full pt-20 carousel-item">
    <img src={t7} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> */}

    
  </div>
    </figure> 
    <div class="card-body">
      <h2 class="card-title">Vitamin and Supplements</h2> 
      <p>Essential Vitamins and Natural Health supplements; Fish Oil, MCT Oil, Bee Propolis</p>
    </div>
  </div>
  
      </div>  

      
  <div class="max-w-md">
    
    <div class="card w-72 card-bordered card-compact lg:card-normal">
    <figure>
    <div class="w-full carousel"> <div id="slide8" class="relative w-full pt-20 carousel-item">
    <img src={a0} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>
    <div id="slide9" class="relative w-full pt-20 carousel-item">
    <img src={a5} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> 
   
    <div id="slide1" class="relative w-full pt-20 carousel-item">
    <img src={a1} class="w-full" style={{borderRadius:"25px"}}/>
 
    </div> 


    <div id="slide2" class="relative w-full pt-20 carousel-item">
    <img src={a2} class="w-full" style={{borderRadius:"25px"}}/>
     
    </div> 
  
    <div id="slide3" class="relative w-full pt-20 carousel-item">
    <img src={a4} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> 
   


    
  </div>
    </figure> 
    <div class="card-body">
      <h2 class="card-title">Sleep & Anxiety </h2> 
      <h2 class="card-title"> </h2> 
      <h2 class="card-title"> </h2> 

      <p>Natural Sleep and Relaxation Aids. Insomnia Relief, Melatonin, 5-HTP, L-Theanine </p>
    </div>
  </div>
  
      </div>  
  </div>

  
</div>

<div class="hero .h-0 bg-base-200">
  <div class="flex-col hero-content lg:flex-row">

    {/* PRODUCT CARD */}

  <div class="max-w-md">
    
    <div class="card w-72 card-bordered card-compact lg:card-normal" 
>
    <figure>
    <div class="w-full carousel">
    <div id="slide1" class="relative w-full pt-20 carousel-item">
    <img src={d3} class="w-full" style={{borderRadius:"25px"}}/>
 
    </div> 

    <div id="slide8" class="relative w-full pt-20 carousel-item">
    <img src={d2} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>


    <div id="slide2" class="relative w-full pt-20 carousel-item">
    <img src={d1} class="w-full" style={{borderRadius:"25px"}}/>
     
    </div> 
    

    
  </div>
    </figure> 
    <div class="card-body">
      <h2 class="card-title">Detox and Cleansing</h2> 
      <p>Cleansing Teas, Oils, Laxatives, Chia Seeds 
         Lung care, Colon Care  </p>
    

    </div>
  </div>
  
      </div>  
      
    {/* PRODUCT CARD 2  */}
  
  <div class="max-w-md">
    
    <div class="card w-72 card-bordered card-compact lg:card-normal">
    <figure>
    <div class="w-full carousel">
    {/* <div id="slide1" class="relative w-full pt-20 carousel-item">
    <img src={t1} class="w-full" style={{borderRadius:"25px"}}/>
 
    </div>  */}

    <div id="slide8" class="relative w-full pt-20 carousel-item">
    <img src={w0} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>


    <div id="slide2" class="relative w-full pt-20 carousel-item">
    <img src={w1} class="w-full" style={{borderRadius:"25px"}}/>
     
    </div> 
    <div id="slide9" class="relative w-full pt-20 carousel-item">
    <img src={w2} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> 
    <div id="slide3" class="relative w-full pt-20 carousel-item">
    <img src={w4} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> 
    <div id="slide4" class="relative w-full pt-20 carousel-item">
    <img src={w5} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>
{/* 
    <div id="slide5" class="relative w-full pt-20 carousel-item">
    <img src={g5} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>

    <div id="slide6" class="relative w-full pt-20 carousel-item">
    <img src={t6} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>

    <div id="slide7" class="relative w-full pt-20 carousel-item">
    <img src={t7} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> */}

    
  </div>
    </figure> 
    <div class="card-body">
      <h2 class="card-title">Weight loss </h2> 
      <h2 class="card-title"> </h2> 
      <p>Teas for weight loss. Fat Burner. Appetite Control </p>

      <p>... </p>
    </div>
  </div>
  
      </div>  

      
  <div class="max-w-md">
    
    <div class="card w-72 card-bordered card-compact lg:card-normal">
    <figure>
    <div class="w-full carousel">
    <div id="slide1" class="relative w-full pt-20 carousel-item">
    <img src={k0} class="w-full" style={{borderRadius:"25px"}}/>
 
    </div> 

    <div id="slide8" class="relative w-full pt-20 carousel-item">
    <img src={k1} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>


    <div id="slide2" class="relative w-full pt-20 carousel-item">
    <img src={k2} class="w-full" style={{borderRadius:"25px"}}/>
     
    </div> 
    <div id="slide9" class="relative w-full pt-20 carousel-item">
    <img src={p3} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> 
    <div id="slide3" class="relative w-full pt-20 carousel-item">
    <img src={k4} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> 
   {/*  <div id="slide4" class="relative w-full pt-20 carousel-item">
    <img src={t4} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>

    <div id="slide5" class="relative w-full pt-20 carousel-item">
    <img src={t5} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>

    <div id="slide6" class="relative w-full pt-20 carousel-item">
    <img src={t6} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>

    <div id="slide7" class="relative w-full pt-20 carousel-item">
    <img src={t7} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> */}

    
  </div>
    </figure> 
    <div class="card-body">
      <h2 class="card-title">Liver and Kidney </h2> 

      <p>Chinese Medicine that supplements liver, kidney, spleen and other organs</p>
    </div>
  </div>
  
      </div>  
      {/* PRODUCT CARD */}
      
      
    
      
      <div class="max-w-md">
    
    <div class="card w-72 card-bordered card-compact lg:card-normal">
    <figure>
    <div class="w-full carousel">
   


    <div id="slide2" class="relative w-full pt-20 carousel-item">
    <img src={p2} class="w-full" style={{borderRadius:"25px"}}/>
     
    </div> 
    <div id="slide9" class="relative w-full pt-20 carousel-item">
    <img src={k3} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> 
    <div id="slide3" class="relative w-full pt-20 carousel-item">
    <img src={p1} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> 

 {/*
    <div id="slide5" class="relative w-full pt-20 carousel-item">
    <img src={o6} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>

    <div id="slide6" class="relative w-full pt-20 carousel-item">
    <img src={t6} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>

    <div id="slide7" class="relative w-full pt-20 carousel-item">
    <img src={t7} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> */}

    
  </div>
    </figure> 
    <div class="card-body">
      <h2 class="card-title">Pain and Inflammation</h2> 
      <p>Traditional Solutions for Pain Relief and Inflammatory Symptoms</p>
    </div>
  </div>
  
      </div>  

      
  <div class="max-w-md">
    
    <div class="card w-72 card-bordered card-compact lg:card-normal">
    <figure>
    <div class="w-full carousel"> <div id="slide8" class="relative w-full pt-20 carousel-item">
    <img src={s1} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div>
    <div id="slide9" class="relative w-full pt-20 carousel-item">
    <img src={s2} class="w-full" style={{borderRadius:"25px"}}/>
      
    </div> 
   



    
  </div>
    </figure> 
    <div class="card-body">
      <h2 class="card-title">Hair and Skin</h2> 

      <h2 class="card-title"></h2> 
      <h2 class="card-title"></h2> 

      <p>Natural Skin Products and Ammonia Free Hair Dye   </p>    
  

    </div>
  </div>
  
      </div>  
  </div>

  
</div>



{/*
cards
<div class="hero .h-0 bg-base-200">
  <div class="flex-col hero-content lg:flex-row">
    <div class="max-w-md">
  
    <div class="card w-72 card-bordered card-compact lg:card-normal">
  <figure>
    <img src="https://picsum.photos/id/1005/400/250"/>
  </figure> 
  <div class="card-body">
    <h2 class="card-title">This card is compact only on small screens</h2> 
    <p>Rerum reiciendis beatae tenetur excepturi aut pariatur est eos. Sit sit necessitatibus veritatis sed molestiae voluptates incidunt iure sapiente.</p>
  </div>
</div>

    </div>   <div class="max-w-md">
  
    <div class="card w-72 card-bordered card-compact lg:card-normal">
  <figure>
    <img src="https://picsum.photos/id/1005/400/250"/>
  </figure> 
  <div class="card-body">
    <h2 class="card-title">This card is compact only on small screens</h2> 
    <p>Rerum reiciendis beatae tenetur excepturi aut pariatur est eos. Sit sit necessitatibus veritatis sed molestiae voluptates incidunt iure sapiente.</p>
  </div>
</div>

    </div> <div class="max-w-md">
    
  <div class="card w-72 card-bordered card-compact lg:card-normal">
  <figure>
    <img src="https://picsum.photos/id/1005/400/250"/>
  </figure> 
  <div class="card-body">
    <h2 class="card-title">This card is compact only on small screens</h2> 
    <p>Rerum reiciendis beatae tenetur excepturi aut pariatur est eos. Sit sit necessitatibus veritatis sed molestiae voluptates incidunt iure sapiente.</p>
  </div>
</div>

    </div>   <div class="max-w-md">
   
  <div class="card w-72 card-bordered card-compact lg:card-normal">
  <figure>
    <img src="https://picsum.photos/id/1005/400/250"/>
  </figure> 
  <div class="card-body">
    <h2 class="card-title">This card is compact only on small screens</h2> 
    <p>Rerum reiciendis beatae tenetur excepturi aut pariatur est eos. Sit sit necessitatibus veritatis sed molestiae voluptates incidunt iure sapiente.</p>
  </div>
</div>

    </div>  
  </div>
</div> */}


<div class="hero min-h-128" style={{ height:'36rem', backgroundImage: `url(${store1})`}}>
  <div class="hero-overlay bg-opacity-60"></div> 
  <div class="text-center hero-content text-neutral-content">
    <div class="max-w-md">
      
      <h1 class="mb-5 text-5xl font-bold">
Traditional Chinese Medicine          </h1> 
      <p class="mb-5">
            We have a wide variety of herbs and natural health products that help reduce reliance on pharmaceutical drug solutions. 
          </p> 
    </div>
  </div>
</div>

{/* style={{ backgroundImage: `url(${dogepfp})`}} */}
<div class="hero min-h-screen" >
  <div class="flex-col hero-content lg:flex-row">  <div class="max-w-md">
      <h1 class="mb-5 text-5xl font-bold">
      4141 Dixie Rd, Mississauga, ON L4W 1V5          </h1> 
      <p class="mb-5">
Monday - Friday 10am - 5pm          </p> 
<p class="mb-5">
Saturday 10am - 4pm          </p> 
<p class="mb-5">
Sunday Closed          </p> 

      <button class="btn btn-primary">905 - 206 - 9050</button>
    </div>  
  <div class="w-full h-full carousel" style={{}}>
  <div id="item1" class="w-full pt-20 carousel-item">
    <img src={rwmall} class="w-full" style={{borderRadius:"25px"}}
/>
  </div> 
  <div id="item2" class="w-full pt-20 carousel-item">
  <img src={inside} class="w-full"/>
  </div> 
  

  
</div> 

  </div>

  
</div>

<div class="hero max-h-screen shadow-lg" style={{ height:'48rem'}} >
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2888.0757905526534!2d-79.60656508426027!3d43.62578146222089!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3880e98a0253%3A0x1e5a097acd3c6ce6!2sGinsengg%20Herbs!5e0!3m2!1sen!2sca!4v1633377708303!5m2!1sen!2sca" width="100%" height="100%"  allowfullscreen="" loading="lazy"></iframe>

</div>


<footer class="p-10 footer bg-base-200 text-base-content footer-center">
  <div class="grid grid-flow-col gap-4">
    905-206-9050
    {/* <a class="link link-hover">About us</a> 
    <a class="link link-hover">Contact</a> 
    <a class="link link-hover">Jobs</a> 
    <a class="link link-hover">Press kit</a> */}
  </div> 
  {/* <div>
    <div class="grid grid-flow-col gap-4">
      <a>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="fill-current">
          <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
        </svg>
      </a> 
      <a>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="fill-current">
          <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
        </svg>
      </a> 
      <a>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="fill-current">
          <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
        </svg>
      </a>
    </div>
  </div>  */}
  <div>
    <p>Copyright © 2021 - All right reserved </p>
  </div>
</footer>



    </div>
  );
}

export default App;
